import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import { withSEO } from 'components/_hoc';
import Login from 'components/Login';

const LOGIN_URL = '/parents/user/login';

/**
 * Parent login page
 */
const ParentLoginPage: FC<{}> = () => {
  const [isVerifying, setIsVerifying] = useState<boolean>(true);

  useEffect((): void => {
    if (window.localStorage.getItem('parent.isAuthenticated') === 'true') {
      navigate('/parent/home');
    } else {
      setIsVerifying(false);
    }
  }, []);

  return isVerifying ? null : <Login page="parent" url={LOGIN_URL} />;
};

export default withSEO(ParentLoginPage, {
  title: 'Login • Parent',
  description:
    "Parent login for Einstein Studios. Sign in to book virtual reality classes, interact with teachers, manage your subscription, and view your student's progress.",
  pathname: '/login/parent',
});
